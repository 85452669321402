import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-63a75221"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category container" }
const _hoisted_2 = { class: "category-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_flex = _resolveComponent("a-flex")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.campusInfo.name) + " - 图形化编程作品", 1),
    _createVNode(_component_a_spin, {
      size: "large",
      spinning: _ctx.categoryLoading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_flex, {
            align: "start",
            wrap: "wrap",
            gap: 30
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryList, (category) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: category.category,
                  class: "category-item",
                  onClick: ($event: any) => (_ctx.toWorksPage(category)),
                  style: { width: '31.35%' }
                }, [
                  _createElementVNode("img", {
                    src: category.cover_image,
                    alt: ""
                  }, null, 8, _hoisted_4),
                  _createElementVNode("p", null, _toDisplayString(category.name), 1)
                ], 8, _hoisted_3))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["spinning"])
  ]))
}