import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78576054"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "work" }
const _hoisted_2 = { class: "campus" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "tag-box" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "tag-list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "works-box" }
const _hoisted_12 = { class: "works" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_button, {
          class: "back",
          type: "text",
          icon: _ctx.h(_ctx.LeftOutlined),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("返回上一页")
          ]),
          _: 1
        }, 8, ["icon"]),
        _createElementVNode("h3", null, _toDisplayString(_ctx.campusInfo.name) + " - " + _toDisplayString(_ctx.curCategoryName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.showHandleBtn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "last",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleScroll('-')))
              }, [
                _createElementVNode("img", {
                  src: _ctx.curHandleStatu == '-' ? _ctx.leftActive : _ctx.leftSrc,
                  alt: "",
                  class: "left-img"
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_spin, {
            size: "small",
            spinning: _ctx.tagLoading
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                style: _normalizeStyle({ padding: _ctx.showHandleBtn ? '0 20px' : '0' })
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "tags",
                    style: _normalizeStyle({ right: `${_ctx.tagRight}px` })
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.curTagId == tag.tag_id
                                        ? 'tag active'
                                        : 'tag'
                                        ),
                        key: tag.tag_id,
                        onClick: ($event: any) => (_ctx.fetchCategoryWorks(tag.tag_id))
                      }, _toDisplayString(tag.name), 11, _hoisted_9))
                    }), 128))
                  ], 4)
                ])
              ], 4)
            ]),
            _: 1
          }, 8, ["spinning"]),
          (_ctx.showHandleBtn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "next",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleScroll('+')))
              }, [
                _createElementVNode("img", {
                  src: _ctx.curHandleStatu == '+' ? _ctx.rightActive : _ctx.rightSrc
                            ,
                  alt: "",
                  class: "right-img"
                }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_a_spin, {
        size: "large",
        spinning: _ctx.worksLoading
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.works, (work) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "work",
                key: work.id,
                onClick: ($event: any) => (_ctx.selectWork(work.id))
              }, [
                _createElementVNode("img", {
                  src: work.work_image,
                  alt: ""
                }, null, 8, _hoisted_14),
                _createElementVNode("p", null, _toDisplayString(work.name), 1)
              ], 8, _hoisted_13))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ])
  ]))
}